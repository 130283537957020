import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { ISearchFilterUpgraded } from '../../models/search-filters.models';
import { filterMappingDeprecated } from './index';

@Component({
  selector: 'popup-multi-choice-filter-dep',
  templateUrl: './popup-multi-choice.component.html',
})
export class PopupMultiChoiceDepComponent implements OnInit, OnDestroy {
  static readonly selector = 'popupMultiChoiceFilter';

  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  private modalInstance?: NgbModalRef;

  constructor(
    private urlService: UrlService,
    private ngModal: NgbModal,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();

      this.modalInstance?.dismiss();
    }
  }

  openFilter() {
    this.modalInstance = this.ngModal.open(filterMappingDeprecated[this.filter.popupOptions.component], {
      injector: this.injector,
    });
    this.modalInstance.componentInstance.filter = this.filter;
    this.modalInstance.componentInstance.modalInstance = this.modalInstance;
  }
}
