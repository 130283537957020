import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from 'app/deprecated/search/models/search-filters.models';
import _ from 'lodash';

@Component({
  selector: 'course-attribute-filter-dep',
  templateUrl: './course-attribute-filter.component.html',
})
export class CourseAttributeFilterDepComponent implements OnInit {
  static readonly selector = 'courseAttributeFilter';

  @Input() filter: ISearchFilterUpgraded;
  title: string;
  items: ISearchFilterItemUpgraded[] = [];
  modalInstance?: NgbActiveModal;

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  ngOnInit() {
    this.items = this.filter.items;
    this.title = this.filter.label;

    this.items.forEach((item) => {
      item.selected = _.some(this.filter.selectedItems, { id: item.id });
    });
  }

  onCheckedChanged(item: ISearchFilterItemUpgraded) {
    if (!this.modalInstance) {
      this.filter.exec(item.id);
    }
  }

  getCheckedNodes(nodes: ISearchFilterItemUpgraded[]): ISearchFilterItemUpgraded[] {
    return _.filter(nodes, (n) => {
      return n.selected && n.id;
    }) as ISearchFilterItemUpgraded[];
  }

  applyFilter() {
    this.filter.clearSelection();

    _.forEach(this.getCheckedNodes(this.items), (item) => {
      this.filter.exec(item.id);
    });

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance.dismiss('cancel');
  }
}
