import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JSONData } from 'core/models';

export const ERROR_NOTIFICATION_TIMEOUT = 5e3;

@Injectable()
export class ErrorsService {
  static readonly defaultMessage = 'The remote server is not available at this time. Try again later.';

  public getErrorDetails(response: HttpErrorResponse): string | JSONData {
    if (response.status >= 500 || response.status === 0) {
      return ErrorsService.defaultMessage;
    } else if (response.status === 413) {
      return 'The file is too large.';
    } else if (response.headers.getAll('content-type')?.join('').includes('text/html')) {
      return ErrorsService.defaultMessage;
    }

    return this.handleErrorMessage(response);
  }

  private handleErrorMessage(response: HttpErrorResponse): string | JSONData {
    return (
      (typeof response.error === 'string' && response.error) ||
      response.error?.detail ||
      response.error?.data ||
      response.error?.message ||
      ErrorsService.defaultMessage
    );
  }
}
