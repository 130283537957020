import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlService } from '@uirouter/core';
import { ISearchFilterItemUpgraded, ISearchFilterUpgraded } from 'app/deprecated/search/models/search-filters.models';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';

interface INode {
  id: number;
  checked: boolean;
  expanded: boolean;
  items: INode[];
  parent: INode;
  typeId?: number;
}

@Component({
  selector: 'session-label-filter-dep',
  templateUrl: './session-label-filter.component.html',
})
export class SessionLabelFilterDepComponent implements OnInit, OnDestroy {
  @Input() filter: ISearchFilterUpgraded;
  sessionLabels: INode[];

  modalInstance?: NgbActiveModal;
  treeControl = new NestedTreeControl<INode>((node) => node.items);
  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(private urlService: UrlService) {}

  get isModal(): boolean {
    return !!this.modalInstance;
  }

  hasChild = (_: number, node: INode) => !!node.items && node.items.length > 0;

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.sessionLabels = this.filter.sessionLabels;
      this.setCheckedNodes(this.sessionLabels);
      this.setParents(this.sessionLabels);
    }
  }

  checkAll(node: INode) {
    if (node.items && node.items.length > 0) {
      node.expanded = true;
      this.propagateChecked(node.items, node.checked);
    }

    // uncheck upward
    if (!node.checked) {
      let parent = node.parent;

      while (parent) {
        parent.checked = false;
        parent = parent.parent;
      }
    }
  }

  propagateChecked(nodes: INode[], checked: boolean) {
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].checked = checked;
      nodes[i].expanded = true;

      if (nodes[i].items && nodes[i].items.length > 0) {
        this.propagateChecked(nodes[i].items, checked);
      }
    }
  }

  getCheckedNodes(nodes: INode[]): ISearchFilterItemUpgraded[] {
    const childNodes = _.reduce(
      nodes,
      (flattened, node) => {
        return flattened.concat(node.items || []);
      },
      [],
    );
    const checkedNodes = _.filter(childNodes, function (n) {
      return n.checked && n.id;
    });

    if (childNodes.length > 0) {
      return checkedNodes.concat(this.getCheckedNodes(childNodes));
    }

    return checkedNodes;
  }

  setParents(nodes: INode[], parent?: INode) {
    if (nodes && nodes.length > 0) {
      _.forEach(nodes, (node) => {
        node.parent = parent;
        this.setParents(node.items, node);
      });
    }
  }

  setCheckedNodes(nodes: INode[]) {
    let hasChecked = false;

    _.forEach(nodes, (node) => {
      node.checked = !!node.typeId && _.some(this.filter.selectedItems, { id: node.id });

      hasChecked = hasChecked || node.checked;

      if (this.setCheckedNodes(node.items)) {
        hasChecked = node.expanded = true;

        // restore label checked state
        if (!node.typeId) {
          node.checked = _.every(node.items, 'checked');
        }
      }
    });

    return hasChecked;
  }

  setCurrentNode(node: INode, selected: boolean) {
    node.checked = selected;

    if (node.items && node.items.length > 0) {
      this.propagateChecked(node.items, selected);
    }
  }

  applyFilter() {
    this.filter.clearSelection();
    _.forEach(this.getCheckedNodes(this.sessionLabels), (item) => {
      this.filter.exec(item.id);
    });

    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  cancelModal() {
    this.modalInstance?.dismiss();
  }
}
