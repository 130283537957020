import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import { ISearchFilterUpgraded } from '../../models/search-filters.models';

@Component({
  selector: 'dates-choice-filter-dep',
  templateUrl: './dates-choice-filter.component.html',
})
export class DatesChoiceFilterDepComponent implements OnInit, OnDestroy {
  static readonly selector = 'datesChoiceFilter';

  @Input() filter: ISearchFilterUpgraded;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;

  constructor(private urlService: UrlService) {}

  ngOnInit(): void {
    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filter.extractConditions();
    }
  }

  dateChanged(event, item) {
    this.filter.exec(event ? new Date(event) : '', item.term);
  }
}
