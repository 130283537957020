export const mainMenuItems = [
  {
    name: 'Home',
    stateName: 'main.home',
  },
  {
    name: 'Welcome',
    stateName: 'main.welcome',
  },
  {
    name: 'My Learning',
    stateName: 'main.learning',
    type: 'custom',
  },
  {
    name: 'Catalog',
    stateName: 'main.fullCatalog',
    stateParams: { type: 'course' },
  },
  {
    name: 'Contact',
    stateName: 'main.contact',
  },
  {
    name: 'Search',
    stateName: 'main.search',
  },
  {
    name: 'Admin Site',
    href: '/lms/admin',
    type: 'custom',
    data: { availableFor: ['admin'] },
  },
];
